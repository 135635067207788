import React from "react"
import "./Card.css"
import {Link} from "gatsby"

const Card = props => (
    <Link className="Card">
    <div className="CardCover">
        <img className="CardImage" alt="Card Images" src={props.image} maxWidth="575px"/>
    </div>
        
    <div className="CardTextGroup"> 
        <h1>{props.title}</h1>
        <h3>{props.caption}</h3>
        <hr></hr>
        <p>{props.text}</p>        
    </div>
        
    </Link>
        
)

export default Card 