import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/Card"



const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    
    <div className="CardGroup">
      <Link to={'/grading'}>
      <Card
        title="Grading at Scale"
        caption="Summer 2021 - present"
        text="To become the best platform for grading."
        image={require('../images/grading-cover.png')}
        
      />
      </Link>

      <Link to={'/autoteams'}>
      <Card
        title="Teams Automation"
        caption="Winter 2019 - Spring 2020"
        text="An efficient way to create teams at large scale using customer data in Learning Pathways."
        image={require('../images/auto-team-cover.png')}
        
      />
      </Link>
      
      <Link to={'/learningpathway'}>
        <Card
            title="Learning Pathways Learner Experience"
            caption="Fall 2018 - Spring 2019"
            text="Improve learning at workspeed for frontline employees."
            image={require('../images/lp-cover.png')}
          />
      </Link>
        
      <Link to={'/crmaccounts'}>
        <Card
          title="CRM Accounts Redesign"
          caption="2017"
          text="A new user interface framework and more."
          image={require('../images/crm-account-cover.png')}
        />
      </Link>
      
      <Link to={'/crmmedia'}>
        <Card
          title="CRM Media Redesign"
          caption="Early 2018"
          text="A new user interface with improved content management."
          image={require('../images/crm-media-cover.png')}
        />
      </Link>

      <Link to={'/myinsights'}>
        <Card
          title="CRM MyInsights Dashboard"
          caption="2017"
          text="Data visualization for immediate field execution."
          image={require('../images/crm-myinsights-cover.png')}
        />
      </Link>
     
      
    </div>
    
  </Layout>
)

export default IndexPage
